import * as constantes from "../constants/constantes.js";
const ENDPOINT = constantes.URLAPI;
const ENDPOINTTEST = constantes.URL_TESTPREGUNTA;

export const ListarPreguntasPorLeccion = async({jwt,idLeccion})=> {
    return await fetch(`${ENDPOINT}/ZADPregunta/ListarPreguntasPorLeccion/${idLeccion}`,{
    //return await fetch(`${ENDPOINTTEST}/ListarPreguntasPorLeccion/${idLeccion}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const ListarPreguntasPorUnidad = async({jwt,idUnidad})=> {
    return await fetch(`${ENDPOINT}/ZADPregunta/ListarPreguntasPorUnidad/${idUnidad}`,{
    //return await fetch(`${ENDPOINTTEST}/ListarPreguntasPorUnidad/${idUnidad}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const EliminarPregunta = async ({jwt,id}) =>{
  return await fetch(`${ENDPOINT}/ZADPregunta/EliminarPregunta/${id}`,{
  //return await fetch(`${ENDPOINTTEST}/EliminarUnidad/${idUnidad}`,{
      method: "GET",
      headers:{
          "Authorization":"Bearer "+jwt,
          //'Content-Type': 'application/json'
          "accept": "text/plain"
      },
      
  }).then(res=>{
      //if(!res.ok) throw new Error("Response is Not Ok")
      if(!res.ok) 
      {
          if(res.status == 401)
          {
              window.localStorage.removeItem('jwt')
              window.location.reload();
          }
          else
          {
              throw new Error("No se recibió respuesta del servidor")
          }
      }
      return res.json()
  }).then(res=>{
      if(res.errors) throw new Error(res.errors[0])
      const {data} = res
      return data
  })
  
}

export const BuscarPreguntaID = async ({jwt,id}) =>{
    return await fetch(`${ENDPOINT}/ZADPregunta/BuscarPreguntaID/${id}`,{
    //return await fetch(`${ENDPOINTTEST}/BuscarPreguntaID/${id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}


export const ActualizarPregunta= ({jsonPregunta,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarPregunta`,{
    return fetch(`${ENDPOINT}/ZADPregunta/ActualizarPregunta`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonPregunta
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const RegistrarPregunta= ({jsonPregunta,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/RegistrarPregunta`,{
    return fetch(`${ENDPOINT}/ZADPregunta/RegistrarPregunta`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonPregunta
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const BuscarRespuestaID = async({jwt,id})=> {
    return await fetch(`${ENDPOINT}/ZADPregunta/BuscarRespuestaID/${id}`,{
    //return await fetch(`${ENDPOINTTEST}/BuscarRespuestaID/${id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const ListarRespuestasPorPregunta = async({jwt,id})=> {
    return await fetch(`${ENDPOINT}/ZADPregunta/ListarRespuestasPorPregunta/${id}`,{
    //return await fetch(`${ENDPOINTTEST}/ListarRespuestasPorPregunta/${id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const ActualizarRespuesta= ({jsonRespuesta,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarRespuesta`,{
    return fetch(`${ENDPOINT}/ZADPregunta/ActualizarRespuesta`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonRespuesta
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const RegistrarRespuesta= ({jsonRespuesta,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/RegistrarRespuesta`,{
    return fetch(`${ENDPOINT}/ZADPregunta/RegistrarRespuesta`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonRespuesta
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}
export const EliminarRespuesta = async ({jwt,id}) =>{
    return await fetch(`${ENDPOINT}/ZADPregunta/EliminarRespuesta/${id}`,{
    //return await fetch(`${ENDPOINTTEST}/EliminarRespuesta/${idUnidad}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
  }