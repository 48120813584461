import React, { useEffect, useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import "./DropdownDefault.scss"
const DropdownDefault = (props) => {
    return ( 
        <Dropdown className="zv-dropdownDefault" {...props}>

        </Dropdown>
     );
}
 
export default DropdownDefault;