import React, { useEffect, useState, useRef } from "react";
import {
  useNavigate,
  useParams,
} from "react-router-dom";

import DropdownDefault from "../../components/Dropdown/DropdownDefault";
import * as Iconsax from "iconsax-react";
import "./Profesor.scss";
import { InputText } from "primereact/inputtext";
import Boton from "../../components/Boton/Boton";
import {
  BuscarProfesorID,
  ActualizarProfesor,
  RegistrarProfesor,
} from "../../service/ProfesorService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputNumber } from "primereact/inputnumber";
import { Password } from "primereact/password";
import { Uploader } from "rsuite";
import * as constantes from "../../constants/constantes.js";
import { getBase64, handleSoloLetrastest } from "../../helpers/helpers";
import {
  ObtenerCursosPorUsuario,
  ObtenerTipoDocumento,
} from "../../service/UsuarioService";
import DatatableDefault from "../../components/Datatable/DatatableDefault";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog"; // For confirmDialog method
import { EliminarPersonaCurso } from "../../service/UsuarioService";
import {
  uploadFiles,
}from "../../service/DigitalOceansService";


import { addLocale } from 'primereact/api';
import useUsuario from "../../hooks/useUsuario.js";
        
const EditarProfesor = () => {
    addLocale("es", {
    weak: "Débil",
      medium: "Medio",
        strong: "Fuerte",
        passwordPrompt: "Escriba una contraseña",
  });
  const navigate = useNavigate();
  const{toastMessage} = useUsuario()
  const [profesor, setProfesor] = useState(null);
  const [modoEdicion, setModoEdicion] = useState(false);
  const [tituloPagina, setTituloPagina] = useState("Crear docente");
  let { id } = useParams();
  //const toast = useRef(null);

  const [fileList, setFileList] = useState([]);
  const [defaultFile, setDefaultFile] = useState([]);
  const [imageBase64, setImageBase64] = useState(null);

  const [listaCursos, setListaCursos] = useState(null);
  const [loadingCurso, setLoadingCurso] = useState(true);

  const [tipoDocumento, setTipoDocumento] = useState(null);
  useEffect(() => {
    const getProfesor = async () => {
      let jwt = window.localStorage.getItem("jwt");
      let idPersona = id;
      await BuscarProfesorID({ jwt, idPersona }).then((data) => {
        setProfesor(data);
        console.log(data)
        setModoEdicion(true);
        setTituloPagina("Editar docente");
        if (data.avatar) {
          let temp = [
            {
              name: data.avatar,
              fileKey: 1,
              //url: constantes.URLBLOB_DOCENTES + "/" + data.avatar,
              url: data.avatar,
            },
          ];
          setDefaultFile(temp);
          setFileList(temp);
        }
      });
    };
    if (id) getProfesor();
  }, [id]);

  useEffect(() => {
    const getCurso = async () => {
      let jwt = window.localStorage.getItem("jwt");
      let idPersona = id;
      await ObtenerCursosPorUsuario({ jwt, idPersona }).then((data) => {
        setListaCursos(data);
        setLoadingCurso(false);
      });
    };
    if (id) getCurso();
  }, [id]);

  useEffect(() => {
    if (fileList.length > 0) {
      getBase64(fileList[0].blobFile).then((result) => {
        setImageBase64(result);
      });
    }
  }, [fileList]);

  useEffect(() => {
    const getTipoDoc = async () => {
      let jwt = window.localStorage.getItem("jwt");
      await ObtenerTipoDocumento({ jwt }).then((data) => {
        setTipoDocumento(data);
      });
    };
    getTipoDoc();
  }, []);

  const schema = Yup.object().shape({
    nombres: Yup.string().required("Nombres es un campo obligatorio"),
    primerApellido: Yup.string().required(
      "Primer apellido es un campo obligatorio"
    ),
    segundoApellido: Yup.string().required(
      "Segundo Apellido es un campo obligatorio"
    ),
    documento: Yup.string()
      .required("Documento es un campo obligatorio")
      .min(8, "Documento debe tener mínimo 8 números"),
    correo: Yup.string()
      .nullable()
      .required("Correo es un campo obligatorio")
      .email("Formato de correo incorrecto."),
    celular: Yup.number()
      .nullable()
      .required("Teléfono es un campo obligatorio"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      idProfesor: profesor ? profesor.idPersona : 0,
      nombres: profesor ? profesor.nombres : "",
      primerApellido: profesor ? profesor.primerApellido : "",
      segundoApellido: profesor ? profesor.segundoApellido : "",
      password: profesor ? profesor.password : "",
      documento: profesor ? profesor.documento : "",
      tipoDocumento: profesor ? profesor.idTipoDocumento : null,
      correo: profesor ? profesor.correo : "",
      celular: profesor ? profesor.celular : "",
      avatar: profesor ? profesor.avatar : null,
      listaDefecto:
        profesor && profesor.avatar
          ? [
              {
                name: profesor.avatar,
                fileKey: 1,
                //url: constantes.URLBLOB_DOCENTES + "/" + profesor.avatar,
                url: profesor.avatar,
              },
            ]
          : [],
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      let imagenBase64 = imageBase64;
      let avatar = imagenBase64 == undefined ? "" : values.avatar
      if (fileList[0] != undefined) {
        if (fileList[0].blobFile != undefined) {
          await uploadFiles(constantes.URLCARPETACURSOS, fileList[0].blobFile).then(data => {
            avatar = constantes.cdnDigitalOcean + "/" + constantes.URLCARPETAAVATARDOCENTE + "/" + fileList[0].blobFile.name;
          });
        }
        else{
          avatar = fileList[0].url
        }
      }
      else {
        avatar = imagenBase64 == undefined ? "" : values.avatar
      }
      let tipoDocumento = imagenBase64 ? fileList[0].blobFile.type : null;
      //let avatar = fileList.length > 0 ? fileList[0].name : null;
      let activo = values.activo;
      let password = values.password;
      let idPersona = values.idProfesor;
      let nombres = values.nombres.trim();
      let primerApellido = values.primerApellido.trim();
      let segundoApellido = values.segundoApellido.trim();
      let ocupacion = values.ocupacion;
      let descripcion = values.descripcion;
      let idTipoDocumento = values.tipoDocumento;
      let documento = values.documento.trim();
      let correo = values.correo.trim();
      let celular = values.celular;
      let idEmpresa = 2;

      //let idEmpresa = IdEmpresa
      let idTipoPersona = 2;

      let jsonPersona = JSON.stringify(
        {
          activo,
          password,
          idPersona,
          nombres,
          primerApellido,
          segundoApellido,
          ocupacion,
          descripcion,
          idTipoDocumento,
          documento,
          correo,
          celular,
          idTipoPersona,
          imagenBase64,
          tipoDocumento,
          avatar,
          idEmpresa,
        },
        null,
        2
      );

      if (modoEdicion) Actualizar({ jsonPersona });
      else {
        Registrar({ jsonPersona });
      }
    },
  });

  const Actualizar = ({ jsonPersona }) => {
    let jwt = window.localStorage.getItem("jwt");
    ActualizarProfesor({ jsonPersona, jwt })
      .then((data) => {
        formik.setSubmitting(false);
        toastMessage.current.show({
          severity: "success",
          summary: "Éxito",
          detail: "Registro actualizado exitosamente.",
          life: 7000,
        });
        navigate(-1);
      })
      .catch((errors) => {
        toastMessage.current.show({
          severity: "error",
          summary: "Error",
          detail: errors.message,
          life: 7000,
        });
        formik.setSubmitting(false);
      });
  };

  const Registrar = ({ jsonPersona }) => {
    let jwt = window.localStorage.getItem("jwt");
    RegistrarProfesor({ jsonPersona, jwt })
      .then((data) => {
        formik.setSubmitting(false);
        toastMessage.current.show({
          severity: "success",
          summary: "Éxito",
          detail: "Registro exitoso.",
          life: 7000,
        });
        navigate(-1);
      })
      .catch((errors) => {
        toastMessage.current.show({
          severity: "error",
          summary: "Error",
          detail: errors.message,
          life: 7000,
        });
        formik.setSubmitting(false);
      });
  };

  const EliminarCurso = (id) => {
    let jwt = window.localStorage.getItem("jwt");
    EliminarPersonaCurso({ jwt, id })
      .then((data) => {
        //formik.setSubmitting(false)
        toastMessage.current.show({
          severity: "success",
          summary: "Éxito",
          detail: "Registro eliminado.",
          life: 7000,
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((errors) => {
        toastMessage.current.show({
          severity: "error",
          summary: "Error",
          detail: errors.message,
          life: 7000,
        });
        //formik.setSubmitting(false)
      });
  };

  const confirmCurso = (id) => {
    confirmDialog({
      message: "Seguro de eliminar curso?",
      header: "Eliminar",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Aceptar",
      accept: () => EliminarCurso(id),
    });
  };

  const accionEditarCursos = (rowData) => {
    return (
      <div className="datatable-accion">
        <div
          className="accion-editar"
          onClick={() =>
            navigate(
              "../Usuario/EditarUsuario/" +
                id +
                "/AsignarCurso/" +
                rowData.idPersonaCurso
            )
          }
        >
          <span>
            <Iconsax.Eye color="#ffffff" />
          </span>
        </div>
        <div
          className="accion-eliminar"
          onClick={() => {
            confirmCurso(rowData.idPersonaCurso);
          }}
        >
          <span>
            <Iconsax.Trash color="#ffffff" />
          </span>
        </div>
      </div>
    );
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="zv-editarProfesor" style={{ paddingTop: 16 }}>
        <ConfirmDialog />

        <div className="header">
          <span style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
            <Iconsax.ArrowCircleLeft size={30}></Iconsax.ArrowCircleLeft>
          </span>
        </div>
        <div className="header-titulo" style={{ marginTop: 16 }}>
          {tituloPagina}
        </div>
        <div className="zv-editarUsuario-body" style={{ marginTop: 16 }}>
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-6">
              <label className="label-form">Nombre</label>
              <InputText
                type={"text"}
                id="nombres"
                name="nombres"
                placeholder="Escribe aquí"
                value={formik.values.nombres}
                onChange={formik.handleChange}
                //onChange={(e)=>handleSoloLetras(e,formik,"nombres")}
                onBlur={formik.handleBlur}
                onKeyPress={(e) => handleSoloLetrastest(e)}
              ></InputText>
              <div className="p-error">
                {formik.touched.nombres && formik.errors.nombres}
              </div>
            </div>
            <div className="field col-12 md:col-6">
              <label className="label-form">Primer Apellido</label>
              <InputText
                type={"text"}
                id="primerApellido"
                name="primerApellido"
                placeholder="Escribe aquí"
                value={formik.values.primerApellido}
                onChange={formik.handleChange}
                //onChange={(e)=>handleSoloLetras(e,formik,"primerApellido")}
                onBlur={formik.handleBlur}
                onKeyPress={(e) => handleSoloLetrastest(e)}
              ></InputText>
              <div className="p-error">
                {formik.touched.primerApellido && formik.errors.primerApellido}
              </div>
            </div>
            <div className="field col-12 md:col-6">
              <label className="label-form">Segundo Apellido</label>
              <InputText
                type={"text"}
                id="segundoApellido"
                name="segundoApellido"
                placeholder="Escribe aquí"
                value={formik.values.segundoApellido}
                onChange={formik.handleChange}
                //onChange={(e)=>handleSoloLetras(e,formik,"segundoApellido")}
                onBlur={formik.handleBlur}
                onKeyPress={(e) => handleSoloLetrastest(e)}
              ></InputText>
              <div className="p-error">
                {formik.touched.segundoApellido &&
                  formik.errors.segundoApellido}
              </div>
            </div>
            <div className="field col-12 md:col-6">
              <label className="label-form">Tipo Documento </label>
              <DropdownDefault
                type={"text"}
                id="tipoDocumento"
                name="tipoDocumento"
                placeholder="Seleccione"
                value={formik.values.tipoDocumento}
                onChange={(e) => {
                  formik.setFieldValue("documento", "");
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                options={tipoDocumento}
                optionLabel="nombre"
                optionValue="id"
              ></DropdownDefault>
              <small className="p-error">
                {formik.touched.tipoDocumento && formik.errors.tipoDocumento}
              </small>
            </div>
            <div className="field col-12 md:col-6">
              <label className="label-form">Documento </label>
              <InputText
                type={"numeric"}
                id="documento"
                name="documento"
                placeholder="Escribe aquí"
                value={formik.values.documento}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={formik.values.tipoDocumento&& formik.values.tipoDocumento == 1 ? 8 :12}
                keyfilter={formik.values.tipoDocumento&& formik.values.tipoDocumento == 1 ? /^\d+$/ : /^[0-9a-zA-Z||-]+$/gi}
                disabled={formik.values.tipoDocumento != null ? false : true}

              ></InputText>
              <div className="p-error">
                {formik.touched.documento && formik.errors.documento}
              </div>
            </div>
            <div className="field col-12 md:col-6">
              <label className="label-form">Correo</label>
              <InputText
                type={"text"}
                id="correo"
                name="correo"
                placeholder="Escribe aquí"
                value={formik.values.correo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></InputText>
              <div className="p-error">
                {formik.touched.correo && formik.errors.correo}
              </div>
            </div>
            <div className="field col-12 md:col-6">
              <label className="label-form">Celular</label>
              <InputNumber
                type={"text"}
                id="celular"
                name="celular"
                placeholder="Escribe aquí"
                value={formik.values.celular}
                onValueChange={formik.handleChange}
                onBlur={formik.handleBlur}
                useGrouping={false}
                maxLength={9}
              ></InputNumber>
              <div className="p-error">
                {formik.touched.celular && formik.errors.celular}
              </div>
            </div>
            <div className="field col-12 md:col-6">
              <label className="label-form">Contraseña</label>

              <Password
                id="Password"
                // className = "grey"

                placeholder="Escribe aquí"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                toggleMask
              />
            </div>
            <div className="field col-12 md:col-12">
              <Uploader
                listType="picture"
                className="zv-fileUploader"
                fileList={defaultFile}
                disabled={fileList.length}
                onChange={setFileList}
                autoUpload={false}
              >
                {/* <button type="button">
                                <Iconsax.Camera></Iconsax.Camera>
                            </button> */}
                <Boton
                  label="Subir ávatar"
                  color="secondary"
                  type="button"
                  style={{ fontSize: 12, width: 160 }}
                ></Boton>
              </Uploader>
            </div>
          </div>
          <div className="zv-editarUsuario-footer">
            <Boton
              label="Guardar cambios"
              style={{ fontSize: 12 }}
              color="primary"
              type="submit"
              loading={formik.isSubmitting}
            ></Boton>
            <Boton
              label="Agregar curso"
              style={{ fontSize: 12 }}
              color="secondary"
              type="button"
              onClick={() =>
                navigate(
                  "../Usuario/EditarUsuario/" + id + "/AsignarCurso/Crear"
                )
              }
            ></Boton>
          </div>
        </div>
        {modoEdicion && (
          <div className="zv-listadecursos-body" style={{ marginTop: 16 }}>
            <div className="header-titulo" style={{ marginTop: 16 }}>
              Lista de Cursos
            </div>
            <DatatableDefault value={listaCursos} loading={loadingCurso}>
              <Column field="idPersonaCurso" header="ID" sortable></Column>
              <Column
                field="curso.nombre"
                header="Nombre de curso"
                sortable
              ></Column>
              <Column
                field="curso.descripcion"
                header="Dscripción"
                sortable
              ></Column>
              <Column
                body={accionEditarCursos}
                style={{ display: "flex", justifyContent: "center" }}
                header="Acciones"
              ></Column>
            </DatatableDefault>
          </div>
        )}
      </div>
    </form>
  );
};

export default EditarProfesor;
