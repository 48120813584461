import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import AWS from "aws-sdk";

import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";
import "./Curso.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUploadAlt,
  faFolderOpen,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  ChangeNameFile,
  CreateDirectory,
  DeleteFile,
  fetchDirectoriesName,
  uploadFiles,
} from "../../service/DigitalOceansService";
import Boton from "../../components/Boton/Boton";
import * as Iconsax from "iconsax-react";
import DatatableDefault from "../../components/Datatable/DatatableDefault";
import { Column } from "rsuite-table";
import { cdnDigitalOcean } from "../../constants/constantes";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import useUsuario from "../../hooks/useUsuario";
const CargaArchivos = () => {
  //const toast = useRef(null);
  const navigate = useNavigate();
  const{toastMessage} = useUsuario()
  let { nombreCarpeta } = useParams();
  const [listaFiles, setListaFiles] = useState(null);
  const [listaDatatable, setListaDatatable] = useState(null);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [filesUpload, setFilesUpload] = useState([]);
  const [visibleDialogCrearCarpeta, setVisibleDialogCrearCarpeta] =
    useState(false);
  const [visibleEditarArchivo, setVisibleEditarArchivo] = useState(false);
  const [nombreArchivoNuevo, setNombreArchivoNuevo] = useState(null);
  const [nombreArchivoAntiguo, setNombreArchivoAntiguo] = useState(null);
  const [extensionArchivo, setExtensionArchivo] = useState(null);
  const [urlArchivo, setUrlArchivo] = useState(null);
  const [nombreCarpetaNueva, setNombreCarpetaNueva] = useState(null);
  const [loading, setLoading] = useState(false);
  const listarDirectorios = () => {
    let arraycarpetas = nombreCarpeta.split("@");
    // fetchDirectoriesName(arraycarpetas[arraycarpetas.length -1],'/',setListaFiles);
    fetchDirectoriesName(
      nombreCarpeta.replace(/@/g, "/"),
      "/",
      setListaFiles
    ).then((e) => {
      setIsLoadingUpload(false);
    });
  };

  useEffect(() => {
    if (nombreCarpeta) {
      listarDirectorios();
    }
  }, [nombreCarpeta]);

  useEffect(() => {
    if (listaFiles) {
      const archivosConLink = listaFiles.map((item) => ({
        ...item,
        link: item.tipo == "archivo" ? ObtenerUrlDeVideo(item.nombre):"",
      }));
      setListaDatatable(archivosConLink);
    }
  }, [listaFiles]);

  //   const CreateDirectory = async () => {
  //     const response = await s3.putObject(paramsCreateDirectory).promise();
  //     console.log("Directorio creado:", response);
  //   };

  useEffect(() => {
    // Inicializar Dropzone.js
    Dropzone.autoDiscover = false;
    const myDropzone = new Dropzone("#myDropzone", {
      autoProcessQueue: false,
      acceptedFiles: ".png, .jpg, .gif, .jpeg, .pdf, .xlsx, .docx, .xlsx, .pptx",
      //addRemoveLinks: true,
      parallelUploads: 10, // Cantidad máxima de procesamiento paralelo
      maxFiles: 20, // El límite superior del número de archivos cargados a la vez
      //uploadMultiple: true, // Carga de varios archivos
      //autoProcessQueue: false, // Auto upload predeterminado a true
      maxFilesize: 21, // MB
      addRemoveLinks: true, // Agregar botón de eliminación
      //acceptFiles: ".jpg, .png, .jpeg, .JPG, .PNG, .JPEG", // tipo de carga
      dictMaxFilesExceeded: "¡Solo puede cargar hasta maxFiles archivos!",
      dictResponseError: "¡Error al cargar el archivo!",
      dictInvalidFileType:
        "No puede cargar este tipo de archivo, el tipo de archivo solo puede ser * .png, * .jpg, * .gif, * .jpeg, * .pdf, * .xlsx, * .docx, * .xlsx, * .pptx",
      dictFallbackMessage: "El navegador no es compatible",
      dictFileTooBig: `El archivo es demasiado grande.`,
      dictRemoveFile: "Quitar",
    });

    // Manejar eventos de Dropzone
    myDropzone.on("addedfile", (file) => {
       // Obtener la extensión del archivo
      const extension = file.name.split(".").pop().toLowerCase();

      // Lista de extensiones válidas configuradas
      const validExtensions = [".png", ".jpg", ".gif", ".jpeg", ".pdf", ".xlsx", ".docx", ".xlsx", ".pptx"];

      // Verificar si la extensión del archivo está en la lista de extensiones válidas
      if (validExtensions.includes(`.${extension}`)) {
        setFilesUpload((prevFilesUpload) => [...prevFilesUpload, file]);
      }
      // console.log("Archivo agregado:", file);
      // if(file.status =="added")
      // {
      //   setFilesUpload((prevFilesUpload) => [...prevFilesUpload, file]);
      // }
      
    });
    myDropzone.on("removedfile", (file) => {
      console.log("Archivo eliminado:", file);
      setFilesUpload((prevFilesUpload) =>
        prevFilesUpload.filter((item) => item !== file)
      );
    });
    // Otras funciones y eventos de Dropzone...

    // Limpieza al desmontar el componente
    return () => {
      // Destruir Dropzone.js
      myDropzone.destroy();
    };
  }, []);

  async function procesarArchivos() {
    for (const file of filesUpload) {
      await uploadFiles(nombreCarpeta.replace(/@/g, "/"), file);
    }
    listarDirectorios();
    toastMessage.current.show({
      severity: "success",
      summary: "Éxito",
      detail: "Archivos subidos correctamente",
      life: 7000,
    });
    const myDropzoneElement = Dropzone.getElement("#myDropzone");
    if (myDropzoneElement) {
      myDropzoneElement.dropzone.removeAllFiles();
    }
  }

  const handleUpload = () => {
    try {
      if (filesUpload.length > 0) {
        console.log("archivos listos para cargar", filesUpload);
        setIsLoadingUpload(true);

        procesarArchivos();
      } else {
        toastMessage.current.show({
          severity: "error",
          summary: "Error",
          detail: "No hay archivos para subir",
          life: 7000,
        });
      }
    } catch (error) {
      toastMessage.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 7000,
      });
    }
  };
  // useEffect(() => {
  //     Dropzone.options.myDropzone = {
  //         autoProcessQueue: false,
  //         //dictDefaultMessage: 'Arrastra y suelta los archivos en esta áera o de click aquí',
  //         acceptedFiles: '.png,.jpg,.gif,.jpeg,.pdf,.xlsx',
  //         //addRemoveLinks: true,
  //         parallelUploads: 10,// Cantidad máxima de procesamiento paralelo
  //         maxFiles: 20, // El límite superior del número de archivos cargados a la vez
  //         //uploadMultiple: true, // Carga de varios archivos
  //         //autoProcessQueue: false, // Auto upload predeterminado a true
  //         maxFilesize : 21, // MB
  //         addRemoveLinks: true, // Agregar botón de eliminación
  //         //acceptFiles: ".jpg, .png, .jpeg, .JPG, .PNG, .JPEG", // tipo de carga
  //         dictMaxFilesExceeded: "¡Solo puede cargar hasta maxFiles archivos!",
  //         dictResponseError: '¡Error al cargar el archivo!',
  //         dictInvalidFileType: "No puede cargar este tipo de archivo, el tipo de archivo solo puede ser * .jpg, *. png, *. jpeg, *. JPG, *. PNG, *. JPEG",
  //         dictFallbackMessage: "El navegador no es compatible",
  //         dictFileTooBig: `El archivo es demasiado grande.`,
  //         dictRemoveFile: "Quitar",
  //         init:function(){

  //             myDrop = this;

  //             submitButton.addEventListener('click', function(){
  //                 myDrop.processQueue();
  //             });

  //             this.on("addedfile", function(file){
  //                 //submitButton.disabled = false;
  //             });

  //             this.on('success', function(file, res){
  //                 console.log('success', file)
  //                 this.removeFile(file);
  //             });

  //             this.on('error', function(file, res){
  //                 console.log(res.errors);
  //                 let msg = res.errors.file.toString();
  //                 //$('.dz-error-message:last > span').text(msg);
  //                 $(file.previewElement).find('.dz-error-message').text(msg);
  //                 //if (file.accepted) {
  //                     let mypreview = document.getElementsByClassName('dz-error');
  //                     let errorDisplay = document.querySelectorAll('[data-dz-errormessage]');
  //                     errorDisplay[errorDisplay.length - 1].innerHTML = msg;
  //                 //}

  //             });

  //             this.on("queuecomplete", function(file) {
  //                 console.log('complete');
  //                 listar(base_directory)
  //             });

  //         }
  //     };
  // }, []);

  const ObtenerUrlDeVideo = (nombre)=>{
    let rutaBase = cdnDigitalOcean;
    let carpetaBase = nombreCarpeta;
    let archivo = nombre;
    let rutaArchivo = encodeURIComponent(archivo);

    return rutaBase + "/" + carpetaBase.replace(/@/g, "/") + "/" + rutaArchivo
  }

  const obtenerExtensionArchivo=(nombreArchivo)=>{
    return nombreArchivo.split('.').pop().toLowerCase();
  }

  const obtenerNombreArchivo=(nombreArchivo)=>{
    return  nombreArchivo.split('.')[0]
  }

  const bodyNombre = (rowData) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
        <div>
          {rowData.tipo == "archivo" ? (
            <FontAwesomeIcon icon={faFileAlt} />
          ) : (
            <FontAwesomeIcon icon={faFolderOpen} />
          )}
        </div>
        <div>
          {rowData.tipo == "carpeta" ? (
            <a href={window.location.href + "@" + rowData.nombre}>
              {" "}
              {rowData.nombre}
            </a>
          ) : (
            <span>{rowData.nombre}</span>
          )}

          {/* <span>{rowData.nombre}</span> */}
        </div>
      </div>
    );
  };
  const bodyLink = (rowData) => {
    if (rowData.tipo == "archivo") {
      
      return (
        <span>
          {ObtenerUrlDeVideo(rowData.nombre)}
        </span>
      );
    }
  };
  const handleEliminar=(nameFile)=>{
    let path = nombreCarpeta.replace(/@/g, "/") +"/"+nameFile
    DeleteFile(path).then(
      data=>{
        toastMessage.current.show({
          severity: "success",
          summary: "Éxito",
          detail: `Archivo ${encodeURIComponent(nameFile)} eliminado exitosamente.`,
          life: 7000,
        });
        listarDirectorios();
      }
    ).catch(res=>{
      toastMessage.current.show({
        severity: "error",
        summary: "Error",
        detail: res,
        life: 7000,
      });
    })
    //alert(path)
  }
  const acciones = (rowData) => {
    return (
      <div className="datatable-accion" style={{width:"100%",justifyContent:"end",height:"100%"}}>
        {rowData.tipo == "archivo" && (
          <div
           
            className="accion-editar"
            onClick={() => {
              let rutaBase = cdnDigitalOcean;
              let carpetaBase = nombreCarpeta;
              let archivo = rowData.nombre;
              let rutaArchivo = encodeURIComponent(archivo);
              window.open(rutaBase + "/" + carpetaBase.replace(/@/g, "/") + "/" + rutaArchivo, "_blank")
            }}
          >
            <span>
              <Iconsax.Eye color="#ffffff" />
            </span>
          </div>
        )}
        {
          rowData.tipo == "archivo" && (
            <div className="accion-editar"
              onClick={()=>{
                setNombreArchivoAntiguo(rowData.nombre)
                setNombreArchivoNuevo(obtenerNombreArchivo(rowData.nombre));
                setExtensionArchivo(obtenerExtensionArchivo(rowData.nombre));
                setUrlArchivo(ObtenerUrlDeVideo(rowData.nombre))
                setVisibleEditarArchivo(true)
              }}
            >
              <span>
                <Iconsax.Edit color="#ffffff" />
              </span>
            </div>
          )
        }
        {
          rowData.tipo == "archivo" && (
            <div
            className="accion-eliminar"
            onClick={() => {
              //confirmCurso(rowData.idCurso)
              handleEliminar(rowData.nombre)
            }}
          >
            <span>
              <Iconsax.Trash color="#ffffff" />
            </span>
          </div>
          )
        }
        {/* <div
          className="accion-eliminar"
          onClick={() => {
            //confirmCurso(rowData.idCurso)
          }}
        >
          <span>
            <Iconsax.Trash color="#ffffff" />
          </span>
        </div> */}
      </div>
    );
  };
  const handleCrearDirectorio = () => {
    if (nombreCarpetaNueva) {
      setLoading(true)
      CreateDirectory(
        nombreCarpeta.replace(/@/g, "/") + "/",
        nombreCarpetaNueva
      )
        .then((data) => {
          setLoading(false)
          toastMessage.current.show({
            severity: "success",
            summary: "Éxito",
            detail: `Carpeta ${nombreCarpetaNueva} creada exitosamente.`,
            life: 7000,
          });
          setVisibleDialogCrearCarpeta(false);
          setNombreCarpetaNueva(null);
          listarDirectorios();
        })
        .catch((res) => {
          setLoading(false)
          toastMessage.current.show({
            severity: "error",
            summary: "Error",
            detail: res,
            life: 7000,
          });
        });
    }
  };

  const handleCambiarNombre =()=>{
    let rutaNueva= nombreCarpeta.replace(/@/g, "/")+"/"+nombreArchivoNuevo+"."+extensionArchivo;
    let rutaAnterior= nombreCarpeta.replace(/@/g, "/")+"/"+nombreArchivoAntiguo;

    ChangeNameFile(rutaAnterior,rutaNueva).then(
      data=>{
        setVisibleEditarArchivo(false)
        toastMessage.current.show({
          severity: "success",
          summary: "Éxito",
          detail: `Nombre archivo modificado`,
          life: 7000,
        });
        listarDirectorios();
       
      }
    )
    .catch(res=>{
      toastMessage.current.show({
        severity: "error",
        summary: "Error",
        detail: res,
        life: 7000,
      });
    })
  }


  const footerContentCrear = (
    <div>
      <Boton
        label="cancelar"
        color="secondary"
        onClick={() => setVisibleDialogCrearCarpeta(false)}
      />
      <Boton label="Crear" color="primary" onClick={handleCrearDirectorio}  loading ={loading}/>
    </div>
  );
  const footerContentEditar = (
    <div>
      <Boton
        label="cancelar"
        color="secondary"
        onClick={() => setVisibleEditarArchivo(false)}
      />
      <Boton label="Guardar" color="primary" onClick={handleCambiarNombre} />
    </div>
  );
  return (
    <div className="zv-carga_archivos-upload" style={{ paddingTop: 16 }}>
      <div className="header-titulo">Archivos</div>
      <div className="zv-carga_archivos-upload-drapzone">
        <form
          action="/upload"
          className="dropzone dz-clickable"
          id="myDropzone"
        >
          <input
            type="hidden"
            name="url_directory"
            id="url_directory"
            value="administracionBancaria"
          />
          <input
            type="hidden"
            name="_token"
            value="5iEL2XlsPJ8lX5XpDcPufk7h66PvAEvO1MPTjzbC"
          />{" "}
          <div className="dz-default dz-message">
            <FontAwesomeIcon icon={faCloudUploadAlt} size="2x" />
            <div className="icon">
              {/* <i className="fas fa-cloud-upload-alt"></i> */}
            </div>
            <p>Arrastre y suelte los archivos en esta áera o de click aquí</p>
            <p>Luego, dé click en el boton "Subir"</p>
          </div>
        </form>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 16,
          }}
        >
          <div>
            Carpeta actual: <strong>{nombreCarpeta.replace(/@/g, "/")}</strong>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 8,
            }}
          >
            <Boton
              label="Crear carpeta"
              color="secondary"
              type="button"
              onClick={() => setVisibleDialogCrearCarpeta(true)}
            ></Boton>
            <Boton
              label="cargar Archivos"
              color="primary"
              loading={isLoadingUpload}
              type="button"
              onClick={handleUpload}
              //disabled={filesUpload.length ==0}
            ></Boton>
          </div>
        </div>
      </div>
      <div className="zv-carga_archivos-upload-data">
        <DatatableDefault
          value={listaDatatable}
          //loading={loading}
          export = {true}
        >
          <Column
            field="nombre"
            header="Nombre"
            body={bodyNombre}
            sortable
          ></Column>
          {/* <Column header="Link" body={bodyLink}></Column> */}
          <Column header="Link" field = "link"></Column>
          <Column
            body={acciones}
            style={{ display: "flex", justifyContent: "center"}}
            bodyStyle={{height: "50px"}}
            header="Acciones"
          ></Column>
        </DatatableDefault>
      </div>
      <Dialog
        header="Crear directorio"
        footer={footerContentCrear}
        visible={visibleDialogCrearCarpeta}
        style={{ width: "40vw", height: "40vh" }}
        onHide={() => setVisibleDialogCrearCarpeta(false)}
      >
        <div className="flex flex-column gap-2">
          <label htmlFor="nombreCarpeta">Nombre</label>
          <InputText
            id="nombreCarpeta"
            value={nombreCarpetaNueva}
            onChange={(e) => setNombreCarpetaNueva(e.target.value)}
          ></InputText>
        </div>
      </Dialog>
      <Dialog
        header="Editar archivo"
        footer={footerContentEditar}
        visible={visibleEditarArchivo}
        style={{ width: "50vw", height: "60vh" }}
        onHide={() => setVisibleEditarArchivo(false)}
      >
        <div className="flex flex-column gap-2">
          <label htmlFor="nombreArchivo">Nombre</label>
          <InputText
            id="nombreArchivo"
            value={nombreArchivoNuevo}
            onChange={(e) => setNombreArchivoNuevo(e.target.value)}
          ></InputText>
          <label htmlFor="extension">Extensión</label>
          <InputText
            id="extension"
            value={extensionArchivo}
            disabled
          ></InputText>
          <label htmlFor="url">URL</label>
          <InputText
            id="url"
            value={urlArchivo}
            disabled
          ></InputText>
        </div>
      </Dialog>
    </div>
  );
};

export default CargaArchivos;
