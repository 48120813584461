import * as constantes from "../constants/constantes.js";
const ENDPOINT = constantes.URLAPI;
const ENDPOINTTEST = constantes.URL_TESTLECCION;

export const ListarLeccionesPorUnidad = async({jwt,idUnidad})=> {
    return await fetch(`${ENDPOINT}/ZADLeccion/ListarLeccionesPorUnidad/${idUnidad}`,{
    //return await fetch(`${ENDPOINTTEST}/ListarLeccionesPorUnidad/${idUnidad}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const BuscarLeccionID = async ({jwt,idLeccion}) =>{
    return await fetch(`${ENDPOINT}/ZADLeccion/BuscarLeccionID/${idLeccion}`,{
    //return await fetch(`${ENDPOINTTEST}/BuscarLeccionID/${idLeccion}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}


export const ActualizarLeccion= ({jsonLeccion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarLeccion`,{
    return fetch(`${ENDPOINT}/ZADLeccion/ActualizarLeccion`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonLeccion
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const RegistrarLeccion= ({jsonLeccion,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/RegistrarLeccion`,{
    return fetch(`${ENDPOINT}/ZADLeccion/RegistrarLeccion`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonLeccion
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const EliminarLecciones = async ({jwt,idLeccion}) =>{
  return await fetch(`${ENDPOINT}/ZADLeccion/EliminarLeccion/${idLeccion}`,{
  //return await fetch(`${ENDPOINTTEST}/EliminarUnidad/${idUnidad}`,{
      method: "GET",
      headers:{
          "Authorization":"Bearer "+jwt,
          //'Content-Type': 'application/json'
          "accept": "text/plain"
      },
      
  }).then(res=>{
      //if(!res.ok) throw new Error("Response is Not Ok")
      if(!res.ok) 
      {
          if(res.status == 401)
          {
              window.localStorage.removeItem('jwt')
              window.location.reload();
          }
          else
          {
              throw new Error("No se recibió respuesta del servidor")
          }
      }
      return res.json()
  }).then(res=>{
      if(res.errors) throw new Error(res.errors[0])
      const {data} = res
      return data
  })
  
}