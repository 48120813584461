export const URLAPI = "https://prdzegelapi.azure-api.net";
export const URLBLOB_BASE = "https://blobplataformavirtual.blob.core.windows.net/adjuntos";

export const URLCARPETACURSOS = 'assets/cursos/img';
export const URLCARPETAAVATARDOCENTE = 'assets/cursos/img';// 'Docentes';


export const URLAVATARDEFAULT = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
export const URLBLOB_CURSOS = URLBLOB_BASE + "/Cursos";
export const URLBLOB_DOCENTES = URLBLOB_BASE + "/Docentes";
export const URLBLOB_DISENADOR = URLBLOB_BASE + "/Disenador";

export const URL_TESTLOGIN = "http://localhost:7110/api";
export const URL_TESTPROFESOR = "http://localhost:7178/api";
export const URL_TESTCURSO = "http://localhost:7116/api";
export const URL_TESTUNIDAD = "http://localhost:7139/api";
export const URL_TESTLECCION = "http://localhost:7215/api";
export const URL_TESTPREGUNTA = "http://localhost:7089/api";
export const URL_GENERAL = "http://localhost:7198/api";
export const URL_TESTMATERIAL = "http://localhost:7003/api";
export const URL_TESTBLIBLIOTECA = "http://localhost:7093/api";
export const URL_TESTREQUISITO = "http://localhost:7192/api";
export const URL_TESTBENEFICIO = "http://localhost:7135/api";
export const URL_TESTDISENADOR = "http://localhost:7257/api";
export const URL_TESTPROGRAMA = "http://localhost:7247/api";

export const accessKeyId = "DO00QKV4CKN9X3LVBW2C";
export const secretAccessKey = "bq6cVk/A5Mtdm0K3ULCCsY8CZYEncEU55ACT4SxuaBg";
export const endpoint = "https://nyc3.digitaloceanspaces.com"
export const bucketZegel = "zegelvirtual"
export const cdnDigitalOcean = "https://zegelvirtual.nyc3.cdn.digitaloceanspaces.com";

// export const URL_NOTIFICACION = "http://localhost:7262/api"
